.styled-card {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-title-styled {
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 1.5rem;
  padding: 2rem 2rem 0 2rem;
}

.card-body-styled {
  padding: 0 2rem 2rem 2rem;
}

.styled-form {
  width: 100%;
}

.form-group-styled {
  position: relative;
  margin-bottom: 1rem;
}

.form-control-styled {
  height: auto;
  border-radius: 2rem;
  padding: 0.75rem 1.5rem;
}

.form-button-styled {
  text-transform: uppercase;
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}
