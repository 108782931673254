.react-sidebar {
  background-color: white;
}

.sidebar-button {
  all: unset;
}

.ruler {
  border-color: #64a19d;
  border-width: 0.25rem;
  width: 3rem;
}
