.group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 736px) {
  .group-item {
    display: block;
    text-align: center;
  }
}
